export const whatWeDoPoints = [
  {
    title: 'Technologie- und Strategieberatung',
    info: 'Von der Strategie über Front- und Backend-Entwicklung, UX-Design, Data-Engineering und Produktinnovation bieten wir Beratung in allen Bereichen der Technologielandschaft.'
  },
  {
    title: 'Software-Entwicklung',
    info: 'Mit weitreichender Erfahrung in den Bereichen Industry 4.0, kundenorientierte und B2B-Lösungen liegt unser Schwerpunkt auf der Erstellung von Web-Apps und Plattformen auf Basis von JS-Technologie (React, Node.js)'
  },
  {
    title: 'Cloud Engineering',
    info: 'Wir erstellen und verwalten unternehmensweite Cloud-Umgebungen, analysieren und planen Migrationen und beaufsichtigen Governance-Prozesse und Automatisierung.'
  },
  {
    title: 'Data Engineering',
    info: 'Unsere Dienstleistungen umfassen den Aufbau von Datenpipelines für große Volumina (Echtzeit- und Batch-Verarbeitung), die Entwicklung von automatisierten Datenqualitätsprüfungen und die Implementierung von komplexen Datenmodellen.'
  },
  {
    title: 'Innovationsteams',
    info: 'Unsere erfahrenen Startup-Teams stellen digitalisierte Geschäftsprozesse bereit und entwickeln vollständig realisierte digitale Produkte auf der Grundlage modernster AI-Technologie.'
  }
]

export const toolkit = [
  'Microsoft Azure',
  'Google Cloud',
  'Amazon WS',
  'Angular JS',
  'JS+CSS+HTML',
  'React',
  'Angular',
  'Node',
  'Python',
  'Apache Spark',
  'Github'
]
