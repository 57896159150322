import React from 'react'
import ContentContainer from '../../../common/Layout/ContentContainer'
import { toolkit, whatWeDoPoints } from './data'
import Accordions from './Accordions'
import Toolkit from './Toolkit'
import TextWithDots from '../../../common/TextWithDots/TextWithDots'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation'

const WhatWeDo = () => (
  <article className='article-padding bg-brand-secondary'>
    <ContentContainer>
      <div className='xl:grid xl:grid-cols-3 xl:gap-5'>
        <FadeInUpAnimation>
          <TextWithDots className='mb-12 xl:col-span-1'>
            was wir tun
          </TextWithDots>
        </FadeInUpAnimation>
        <div className='xl:col-span-2 mb-28 text-4xl leading-snug font-light lg:text-6xl xl:grid xl:grid-cols-3 xl:gap-5'>
          <FadeInUpAnimation
            className='xl:col-span-3 text-2xl leading-8 font-light lg:text-5xl lg:leading-snug'
            as='p'
          >
            Wir helfen Ihnen bei der Auswahl der für{' '}
            <strong className='text-brand-primary'>
              Ihren Fall geeigneten Technologie,
            </strong>{' '}
            entwerfen und liefern außergewöhnliche Softwarelösungen.
          </FadeInUpAnimation>
        </div>
      </div>
      <Accordions
        className='mb-36'
        points={whatWeDoPoints}
      />
      <Toolkit toolkit={toolkit} />
    </ContentContainer>
  </article>
)

export default WhatWeDo
