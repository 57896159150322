import TextWithDots from '../../common/TextWithDots/TextWithDots'
import React from 'react'
import ContentContainer from '../../common/Layout/ContentContainer'
import TgcLink from '../../common/TgcLink/TgcLink'
import FadeInUpAnimation from '../../common/FadeInUpAnimation/FadeInUpAnimation'

const WhoWeAre = () => (
  <ContentContainer
    as='article'
    className='xl:grid xl:grid-cols-3 xl:gap-5'
  >
    <FadeInUpAnimation>
      <TextWithDots className='mb-12 xl:col-span-1'>Über uns</TextWithDots>
    </FadeInUpAnimation>
    <div className='xl:col-span-2'>
      <FadeInUpAnimation
        className='text-2xl leading-8 font-light lg:text-5xl lg:leading-snug'
        as='p'
      >
        the.good.code wurde von ehemaligen Technologieexperten von Microsoft,
        Intel und P&G gegründet und arbeitet mit führenden Web- und
        Cloud-Technologien, um geschäftsverändernde Produkte und
        Dienstleistungen anzubieten.
      </FadeInUpAnimation>
      <FadeInUpAnimation className='xl:text-right'>
        <TgcLink
          className='inline-block my-8'
          href='/uber-uns/'
        >
          Mehr wissen
        </TgcLink>
      </FadeInUpAnimation>
    </div>
  </ContentContainer>
)

export default WhoWeAre
