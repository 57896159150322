import React, { forwardRef } from 'react'
import ContentContainer from '../../../common/Layout/ContentContainer'
import TgcLink from '../../../common/TgcLink/TgcLink'
import TextWithDots from '../../../common/TextWithDots/TextWithDots'
import Projects from '../../../common/Projects/Projects'
import { graphql, useStaticQuery } from 'gatsby'
import { map, sortBy } from 'lodash'

const query = graphql`
  query getFeaturedWork {
    allFile(
      filter: {
        sourceInstanceName: { eq: "content" }
        relativeDirectory: { eq: "works" }
        childMarkdownRemark: { frontmatter: { featured: { eq: true } } }
      }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              id
              url
              title
              subTitle
              locale
              imgs {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`

const FeaturedWork = forwardRef((props, triggerRef) => {
  const {
    allFile: { edges }
  } = useStaticQuery(query)

  const projects = map(edges, 'node.childMarkdownRemark.frontmatter').filter(
    (elem) => elem.locale === 'de'
  )
  const sortedProjects = sortBy(projects, (project) => project.id)

  return (
    <div className='bg-white'>
      <article
        className='article-padding bg-white'
        ref={triggerRef}
      >
        <ContentContainer className='relative'>
          <TextWithDots className='mb-12'>Vorgestellte Arbeiten</TextWithDots>
          <Projects sortedProjects={sortedProjects} />
          <TgcLink
            className='inline-block lg:absolute lg:top-0 lg:right-0 lg:mr-12 lg:mt-3'
            href='/arbeit/'
          >
            Alle Projekte ansehen
          </TgcLink>
        </ContentContainer>
      </article>
    </div>
  )
})

FeaturedWork.displayName = 'FeaturedWork'

export default FeaturedWork
