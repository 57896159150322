export const cooperatingContentText = [
  {
    id: '01',
    title: 'Teamvergrößerung',
    description:
      'Mit der Möglichkeit, als interne Ressource zu agieren, können unsere qualifizierten Ingenieure Ihrem Team interne Unterstützung bei der Softwareentwicklung bieten. Sie sind ideal platziert, um Ihre Teams während einer langfristigen Zusammenarbeit zu verstärken.'
  },
  {
    id: '02',
    title: 'Projekt-basierend',
    description:
      'Wir stellen ein komplettes Team und die notwendigen Fähigkeiten zur Verfügung, um das gesamte Projekt durchzuführen, einschließlich aller Phasen des Softwareentwicklungslebenszyklus und des Managements. Die Einbindung des Kunden ist nur für den Teil der Anforderungen und Spezifikationen erforderlich.'
  },
  {
    id: '03',
    title: 'Consulting',
    description:
      'Unsere Berater können sich schnell einen Überblick über Ihre Bedürfnisse verschaffen, Probleme lösen und Elemente entfernen, die den Fortschritt behindern. Wir bieten eine flexible Abrechnung auf der Grundlage eines Stundensatzes.'
  }
]
