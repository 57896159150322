import React from 'react'
import Layout from '../../components/de/Layout/Layout'
import Home from '../../components/de/Home/Home'

const IndexPage = () => (
  <Layout
    indexPage
    title='the.good.code; | Hochwertige IT-Dienstleistungen - Verbessern Sie Ihre digitale Präsenz'
    description='the.good.code; | bietet hochwertige IT-Dienstleistungen, die auf die Bedürfnisse Ihres Unternehmens zugeschnitten sind. Kontaktieren Sie uns noch heute und erfahren Sie, wie wir Ihnen helfen können, Ihre digitale Präsenz zu verbessern.'
  >
    <Home />
  </Layout>
)

export default IndexPage
