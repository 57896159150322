import ContentContainer from '../../../common/Layout/ContentContainer'
import TextWithDots from '../../../common/TextWithDots/TextWithDots'
import React from 'react'
import TestimonialsCarousel from '../../../common/Testimonials/Carousel/TestimonialsCarousel'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query getRelatedTestimonials {
    allFile(
      filter: {
        sourceInstanceName: { eq: "content" }
        relativeDirectory: { eq: "testimonials" }
        name: { regex: "/[.]de/" }
      }
      sort: { fields: [childMarkdownRemark___frontmatter___id], order: ASC }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              clientReview
              clientAvatar {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    height: 100
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
              clientName
              clientPosition
            }
          }
        }
      }
    }
  }
`

const Testimonials = () => {
  const data = useStaticQuery(query)

  return (
    <div className='article-padding bg-brand-secondary'>
      <ContentContainer
        as='article'
        className='xl:grid xl:grid-cols-4 xl:gap-5 min-h-fit'
      >
        <FadeInUpAnimation className='mb-12 xl:col-span-2'>
          <TextWithDots>Empfehlungsschreiben</TextWithDots>
        </FadeInUpAnimation>
        <FadeInUpAnimation className='mb-12 xl:col-span-2'>
          <p className='text-2xl leading-8 font-light lg:text-5xl lg:leading-snug'>
            Hier ist, was einige unserer Kunden über die Macht von the.good.code
            zu sagen haben:
          </p>
        </FadeInUpAnimation>
        <FadeInUpAnimation className='xl:col-span-4 mt-10'>
          <TestimonialsCarousel data={data} />
        </FadeInUpAnimation>
      </ContentContainer>
    </div>
  )
}

export default Testimonials
